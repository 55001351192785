
import React from "react";
import PortfolioContainer from "./component/PortfolioContainer";


const App = () => <PortfolioContainer /> ;

export default App;




